import React from 'react';

const TreatmentSelector = ({ treatments, onSelectTreatment }) => {
    return (
        <div className="booking-widget__treatment-selector">
            {treatments.map((treatment, index) => (
                <div
                    key={index}
                    className="booking-widget__treatment"
                    onClick={() => onSelectTreatment(treatment)}
                >
                    <h3 className="booking-widget__treatment-title">{treatment}</h3>
                </div>
            ))}
        </div>
    );
};

export default TreatmentSelector;