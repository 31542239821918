import React from 'react';
const LocationSelector = ({ locations, onSelectLocation }) => {
    return (
        <div className="booking-widget__location-selector">
            {locations.map(location => (
                <div
                    key={location.ID}
                    className="booking-widget__location"
                    onClick={() => onSelectLocation(location)}
                >
                    <h3 className="booking-widget__location-title">{location.title}</h3>
                    <p className="booking-widget__location-address">
                        {location.street}, {location.zip} {location.city}
                    </p>
                    <p className="booking-widget__location-contact">
                        Tel: {location.phone} | Email: {location.email}
                    </p>
                    <div className="booking-widget__location-treatments">
                        <h4>Verfügbare Behandlungen:</h4>
                        <ul>
                            {location.treatments.map((treatment, index) => (
                                <li key={index}>{treatment}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LocationSelector;