import {FETCH_SLOTS,SELECT_DATE,SELECT_TIME,CONFIRM_TIME} from "../actions/types";

const INIITAL_STATE = {
  slots: [],
  selectedDate: null,
  selectedTime: null,
  confirmedTime: null,
};

export default (state = INIITAL_STATE, action) => {
    switch (action.type) {
        case FETCH_SLOTS:
            return {...state, slots: action.payload.data};
        case SELECT_DATE:
            return {...state, selectedDate: action.payload};
        case SELECT_TIME:
            return {...state, selectedTime: action.payload};
        case CONFIRM_TIME:
            return {...state, confirmedTime: action.payload};
        default:
            return state;
    }
};