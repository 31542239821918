import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { selectTime, confirmTime } from "../actions";

const SelectTimeItem = ({ slot }) => {
    const dispatch = useDispatch();
    const selectedSlot = useSelector(state => state.dateTimeInfo.selectedTime);
    const selected = slot === selectedSlot;

    return (
        <div className={`field select-time-item`}>
            <div className={'control'}>
                <button className={`button select-time is-info ${!selected ? 'is-outlined is-expanded' : ''}`} onClick={() => dispatch(confirmTime(slot))}>{slot.time}</button>
            </div>
        </div>
    );
};

export default SelectTimeItem;