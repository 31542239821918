import crm_api from "../apis/crm_api";
import {
    SELECT_DATE,
    FETCH_SLOTS,
    SELECT_TIME,
    CONFIRM_TIME,
    SET_ROOT,
    FETCH_SETTINGS,
    SHOW_THANKYOU,
    SHOW_ERROR,
    SET_STEP,
    SELECT_LOCATION,
    SET_LOCATIONS,
    SET_OPTIONS, SELECT_TREATMENT
} from "./types";

export const saveAppointment = (formValues,confirmedTime) => dispatch => {
    crm_api.post('/booking/',{formValues,confirmedTime}).then((response) => {
        if(response.status===201)
        {
            dispatch({type: SHOW_THANKYOU});
        }
        else
        {
            dispatch({type: SHOW_ERROR});
        }
    });
}

export const selectDate = (day) => {
    return {
        type: SELECT_DATE,
        payload: day,
    }
};

export const selectTime = (slot) => {
    return {
        type: SELECT_TIME,
        payload: slot,
    }
};

export const confirmTime = (slot) => {
    return {
        type: CONFIRM_TIME,
        payload: slot,
    }
};

export const showError = (message = '') => ({
    type: SHOW_ERROR,
    payload: message
});

export const selectLocation = (location = null) => ({
    type: SELECT_LOCATION,
    payload: location
})


export const selectTreatment = (treatment = null) => ({
    type: SELECT_TREATMENT,
    payload: treatment
})


export const setLocations = (locations = []) => ({
    type: SET_LOCATIONS,
    payload: locations
})

export const setOptions = (options = {}) => ({
    type: SET_OPTIONS,
    payload: options
})

export const fetchLocationDetails = (root) => async dispatch => {
    try {
        // Set the root URL for API calls
        crm_api.defaults.baseURL = root;
        dispatch({ type: SET_ROOT, payload: root });

        // Perform concurrent API requests
        const [slotsResponse, settingsResponse] = await Promise.all([
            crm_api.get('/booking/slots'),
            crm_api.get('/booking/settings')
        ]);

        // Dispatch actions with the results of the API calls
        dispatch({ type: FETCH_SLOTS, payload: slotsResponse });
        dispatch({ type: FETCH_SETTINGS, payload: settingsResponse });

    } catch (error) {
        console.error('Error in fetchLocationDetails:', error);
        // Handle errors, maybe dispatch an error action
        console.error('Error fetching location details:', error);
        dispatch(showError(''))
    }
};