
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form'; // You'll need to install react-hook-form
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { saveAppointment } from "../actions";

const validateEmail = email => {
    return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(email);
};

const Form = () => {
    const dispatch = useDispatch();
    const confirmedTime = useSelector(state => state.dateTimeInfo.confirmedTime);
    const privacyText = useSelector(state => state.gui.location.privacy); // Assuming 'location' is the state where privacy text is stored
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        dispatch(saveAppointment(data, confirmedTime));
    };

    return (
        <div className={'booking-form'}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Field label='Name' type='text' name='name' register={register} errors={errors} />
                <Field label='Telefon' type='tel' name='phone' register={register} errors={errors} />
                <Field label='E-Mail-Adresse' type='email' name='mail' register={register} errors={errors} validateEmail={validateEmail} />
                {/* Implement Checkbox Field as needed */}

                <div className="field">
                    <div className="control">
                        <label className="checkbox">
                            <input type="checkbox" {...register('datenschutz')} />
                            Ich stimme den Datenschutzbestimmungen zu
                        </label>
                        <div dangerouslySetInnerHTML={{ __html: privacyText }} />
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <button className="button is-primary">Termin buchen</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const Field = ({ label, type, name, register, errors, validateEmail }) => {
    return (
        <div className="field">
            <label className="label">{label}</label>
            <div className="control has-icons-left has-icons-right">
                <input className={`input ${errors[name] ? 'is-danger' : ''}`} autoComplete="off" type={type} {...register(name, { validate: type === 'email' ? validateEmail : undefined })} />
                {errors[name] && <p className="help is-danger">{errors[name].message}</p>}
            </div>
        </div>
    );
};

export default Form;
