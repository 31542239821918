import {
    SELECT_TREATMENT,
    SELECT_LOCATION,
    FETCH_SLOTS,
    FETCH_SETTINGS,
    SELECT_DATE,
    SELECT_TIME,
    CONFIRM_TIME,
    SET_ROOT,
    SHOW_ERROR,
    SHOW_THANKYOU
} from "../actions/types";

const INITIAL_STATE = {
    location: null,
    treatment: null,
    expanded: false,
    loading: false,
    form: false,
    error: false,
    errorMessage: null,
    success: false,
    step: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SELECT_LOCATION:
            return {
                ...state,
                location: action.payload,
                step: action.payload.treatments?.length === 0 ? 2 : 1,
                loading: true
            };
        case SELECT_TREATMENT:
            return {
                ...state,
                treatment: action.payload,
                step: 2
            };
        case SET_ROOT:
            return {...state, root: action.payload};
        case FETCH_SLOTS:
            return {...state, expanded: false, form: false, error: false, success: false, loading: false};
        case FETCH_SETTINGS:
            return {...state, settings: action.payload.data ,expanded: false, form: false, error: false, success: false, loading: false};
        case SELECT_DATE:
            return {...state, expanded: true, form: false, error: false, success: false};
        case SELECT_TIME:
            return {...state, expanded: true, form: false, error: false, success: false, step: 3};
        case CONFIRM_TIME:
            return {...state, expanded: false, form: true, error: false, success: false, step: 3};
        case SHOW_ERROR:
            return {...state, expanded: false, form: false, error: true, success: false, errorMessage: action.payload};
        case SHOW_THANKYOU:
            return {...state, expanded: false, form: false, error: false, success: true};
        default:
            return state;
    }
};