import {
    SET_LOCATIONS,
    SET_OPTIONS
} from "../actions/types";

const INITIAL_STATE = {
    options: {},
    locations: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOCATIONS:
            return {...state, locations: action.payload};
        case SET_OPTIONS:
            return {...state, options: action.payload};
        default:
            return state;
    }
};